import tippy from "tippy.js";

$(document).ready(function () {
  tippy('.tooltip', {
    content(reference) {
      const id = reference.getAttribute('data-template');
      const template = document.getElementById(id);
      return template.innerHTML;
    },
    placement: 'bottom',
    allowHTML: true,
    // trigger: 'click',
    // hideOnClick: false
  });
})
